import React, { useEffect, useState, useRef } from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import { BiLoader } from 'react-icons/bi'
import SocialMedia from '../components/SocialMedia'
export default function LavenderVillaShow() {
    // Replace the PDF URL with your publicly accessible PDF file on Google Drive

    const [page, setPage] = useState(1);

    const canvasRef = useRef(null);



    useEffect(() => {
        if (canvasRef.current != null) {
            let canvas = document.getElementById("cv");
            canvas.dir = "ltr"
        }
    }, [canvasRef]);

    const { pdfDocument } = usePdf({
        file: "pdf/lavenderVilla.pdf",
        page,
        canvasRef,
        scale: 0.50,

    });




    return (
        <div style={{ backgroundImage: `url(${"img/patternWhite.svg"})`, backgroundPosition: "center" }} className='grid grid-cols-1  container px-4 mx-auto  place-items-center h-screen w-screen mx-auto  '>
            <div className="flex items-center justify-around w-full">
                <img className="w-24 h-24" src="img/awtad-logo.png" alt="" />
                <img className="w-36 h-24" src="img/portfolio/lavenderVilla.jpeg" alt="" />
            </div>
            {!pdfDocument &&
                <div className='flex flex-col items-center justify-center pt-10 mx-auto w-full'>
                    <BiLoader className='w-20 h-20 animate-spin' />
                    <>جاري تحميل الملف</>
                </div>
            }
            <canvas className='' id='cv' ref={canvasRef} />
            {Boolean(pdfDocument && pdfDocument.numPages) && (
                <div className='flex flex-row items-center justify-between py-2 mx-auto w-72'>

                    <div >
                        <button className='w-20 p-1 border-2 border-dashed border-black-500 rounded-xl' disabled={page === 1} onClick={() => setPage(page - 1)}>
                            السابق
                        </button>
                    </div>
                    {pdfDocument.numPages} / {page}
                    <div >
                        <button
                            className='w-20 p-1 border-2 border-dashed border-black-500 rounded-xl'
                            disabled={page === pdfDocument.numPages}
                            onClick={() => setPage(page + 1)}
                        >
                            التالي
                        </button>
                    </div>

                </div>
            )}
            <SocialMedia />
        </div>
    );

};


