export const data = {
  "Header": {
    "title": "خدمات عقارية متكاملة",
    "paragraph": "منشأة عقارية حاصلة على تصنيف رقم 1 من الهيئة العامة للعقار"
  },
  "About": {
    "paragraph": "تأسس مكتب اوتاد العقار للعقارات عام 2008م بمدينة الرياض ، وذلك بغرض القيام بتسويق العقارات المؤجرة والغير مؤجرة  وإدارة الأملاك نيابة عن الغير وتسويق وبيع العقارات  ، كما نقوم بعمل دراسات لأفضل استخدام وافضل قيمة للعقارات  من خلال تحليل حركة السوق العقاري ورصد المتغيرات والأحداث التي تساهم في تكوين اتجاهاته",
    "Why": [
      "  اتخاذ احدث طرق التسويق العقاري مما يؤدي الى سرعة الوصول للأهداف المرجوة من تسويق العقار وكسب ثقة عملاءنا سواء في تسويق العقار بغرض البيع أو التأجير "

    ],
    "Why2": [
      " الوصول إلى مكانة جيدة في تقديم الخدمات العقارية "

    ]
  },
  "Gallery": [
    {
      "title": "مخطط الغدير بالخرج ",
      "Image": "img/portfolio/alghadir.jpg",
    },
    {
      "title": "مخطط الغدير  بالمدنية المنورة ",
      "Image": "img/portfolio/alghadir2.jpg",

    },
    {
      "title": " نسائم العمارية",
      "Image": "img/portfolio/amariya.jpg"
    },
    {
      "title": " نسائم العمارية 2",
      "Image": "img/portfolio/amariya2.jpg"
    },
    {
      "title": "فلل اوتاد السكنية ",
      "Image": "img/portfolio/awtadVla.jpg"
    },
    {
      "title": "مشروع نور شقق تمليك",
      "Image": "img/portfolio/noor.jpg"
    },
    {
      "title": " مشروع روز شقق تمليك",
      "Image": "img/portfolio/roseRyaidh.jpeg"
    },
    {
      "title": " مشروع قويارد فلل",
      "Image": "img/portfolio/goYard.jpeg"
    },
    {
      "title": " مشروع لافندر فلل",
      "Image": "img/portfolio/lavenderVilla.jpeg"
    },
    {
      "title": " مشروع لبنى فلل",
      "Image": "img/portfolio/lubnaResidence.jpeg"
    },
    {
      "title": " مشروع الدانة شقق تأجير",
      "Image": "img/portfolio/dana.jpeg"
    }
     


  ],
  "Contact": {
    "address": "الرياض - النرجس - طريق ابو بكر الصديق",
    "phone": "0555737001",
    "telphone": "0114545646",
    "snapchat": "https://www.snapchat.com/add/awtadalaqar",
    "email": "info@awtadd.com",
    "instagram": "https://www.instagram.com/awtadalaqar/",
    "twitter": "https://twitter.com/AwtadAlaqar",
    "whatsapp": "https://api.whatsapp.com/send?phone=966555737001"
  },
  "Features": [
    {
      "icon": "fa fa-magic",
      "title": "خدمة الاستشارات ",
      "text": "تقديم خدمة الاستشارات العقارية ودراسة أفضل استخدام ودراسة الجدوى العقارية"
    },
    {
      "icon": "fa fa-bullhorn",
      "title": "خدمة المزادات",
      "text": "تقديم خدمة المزادات العقارية على جميع انواع العقارات بمنهجية عالية"
    },
    {
      "icon": "fa fa-comments-o",
      "title": "خدمة التقييم ",
      "text": "تقييم جميع انواع العقارات معتمد لدى الهئية السعودية للمقيمين المعتمدين"
    },
    {
      "icon": "fa fa-group",
      "title": "خدمة التسويق",
      "text": "تقديم خدمات التسويق العقاري على جميع انواع العقارات بأسلوب مهني احترافي"
    }
  ]
}
