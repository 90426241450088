import React from "react";
import { RiTwitterXLine as TwitterXLine } from "react-icons/ri";
import { FaSnapchatGhost as SnapchatGhost, FaTiktok as Tiktok, FaWhatsapp as Whatsapp, FaInstagram as Instagram } from "react-icons/fa";

export default function SocialMedia() {

    return (
        <div className="flex flex-wrap justify-center items-center gap-5">
            <a target="_blank" href="https://twitter.com/AwtadAlaqar">
                <TwitterXLine className="p-2 text-white bg-blue-500 rounded-lg w-10 h-10" />
            </a>
            <a target="_blank" href="https://www.instagram.com/awtadalaqar/">
                <Instagram className="p-2 text-white bg-blue-500 rounded-lg w-10 h-10" />
            </a>
            <a target="_blank" href="https://www.snapchat.com/add/awtadalaqar">
                <SnapchatGhost className="p-2 text-white bg-blue-500 rounded-lg w-10 h-10" />
            </a>
            <a target="_blank" href="https://api.whatsapp.com/send?phone=966555737001">
                <Whatsapp className="p-2 text-white bg-blue-500 rounded-lg w-10 h-10" />
            </a>
            <a target="_blank" href="https://vm.tiktok.com/ZSLs1Ds5A/">
                <Tiktok className="p-2 text-white bg-blue-500 rounded-lg w-10 h-10" />
            </a>
        </div>
    )
}