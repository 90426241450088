import * as React from "react";
import * as ReactDOM from "react-dom";
import { HashRouter, Routes, Route } from "react-router-dom";
import './index.css';
import Landing from './pages/Landing';
import LavenderVilla from './pages/LavenderVillaShow';
import * as serviceWorker from './serviceWorker';

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Landing />,
//   },
//   {
//     path: "/lavenderVilla",
//     element: <LavenderVilla />,
//   },
// ]);
ReactDOM.render(
  <React.StrictMode>
    <HashRouter basename="/">
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/lavenderVilla" element={<LavenderVilla />} />
      </Routes>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
