
import React from "react";
import { BiBarChartSquare, BiDirections, BiAward, BiNews, BiCurrentLocation, BiMapPin, BiPhone, BiMobileAlt } from 'react-icons/bi'
import { RiTwitterXLine as TwitterXLine } from "react-icons/ri";
import { FaSnapchatGhost as SnapchatGhost, FaTiktok as Tiktok, FaWhatsapp as Whatsapp, FaInstagram as Instagram } from "react-icons/fa";
import { data } from "../data/data";
import SocialMedia from '../components/SocialMedia'
export default function Home() {


    return (
        <>
            <div className="overflow-scroll bg-white " style={{ backgroundImage: `url(${"img/patternWhite.svg"})`, backgroundPosition: "center" }} ><div className="bg-tarnsparent">
                <nav className="justify-between p-6 px-4" >
                    <div className="flex items-center justify-center w-full">
                        <img className="w-32 h-24" src="img/awtad-logo.png" alt="" />
                    </div>
                </nav >
            </div >
                <div className="py-10 md:py-20">
                    <div className="container px-4 mx-auto">
                        <div className="flex flex-wrap-reverse -mx-4 xl:items-center">
                            <div className="w-full px-4 mb-16 md:w-1/2 md:mb-0">
                                <div className="my-10 text-4xl font-bold leading-10 text-center ">
                                    <h1 className="mb-6 text-3xl font-bold text-center" >خدمات عقارية متكاملة</h1>
                                </div>
                                <h2 className="my-6 text-2xl text-center ">منشأة عقارية حاصلة على تصنيف رقم 1 من   </h2>
                                <div className="flex flex-wrap">
                                    <div className="flex items-center justify-center w-full py-10 mx-auto">
                                        <img className='rounded-xl w-80 h-72' src="img/rga.svg" alt="" />
                                    </div>
                                    <div className="flex flex-row items-center justify-center mx-auto">
                                        <a className="w-[270px]  h-20 py-2  sm:cursor-pointer  bg-blue-600  inline-flex  gap-5  items-center justify-center  text-2xl  shadow-md  duration-300 text-white  rounded-xl " href="#">تواصل معنا</a>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full px-4 md:w-1/2">
                                <div className="relative mx-auto md:mr-0 max-w-max">
                                    <img className="relative rounded-xl" src="img/intro-bg.jpg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="py-3 bg-white md:pb-32" style={{ backgroundImage: `url(${"img/patternWhite.svg"})`, backgroundPosition: "center" }}>
                    <div className="container px-4 mx-auto">
                        <div className="mx-auto mb-6 text-center p-5">

                            <h1 className="mb-4 text-3xl font-bold leading-tight tracking-tighter md:text-4xl"> عن اوتاد العقار</h1>
                            <p className="text-lg text-justify font-medium md:text-xl "> {data.About.paragraph}</p>
                        </div>
                        <div className="mx-auto mb-12 text-center md:max-w-4xl">

                            <h1 className="mb-4 text-3xl font-bold leading-tight tracking-tighter md:text-4xl">الخدمات المقدمة</h1>

                        </div>
                        <div className="flex flex-wrap -mx-4">
                            <div className="w-full px-4 md:w-1/2 lg:w-1/4">
                                <div className="h-full p-8 text-center transition duration-200 rounded-xl hover:bg-white hover:shadow-xl">
                                    <div className="inline-flex items-center justify-center w-16 h-16 mx-auto mb-6 text-white bg-blue-500 rounded-xl">
                                        <BiBarChartSquare className="w-8 h-8" />
                                    </div>
                                    <h3 className="mb-4 text-xl font-bold leading-tight md:text-2xl">  التسويق
                                    </h3>
                                    <p className="font-medium ">قديم خدمات التسويق العقاري على جميع انواع العقارات بأسلوب مهني احترافي </p>
                                </div>
                            </div>
                            <div className="w-full px-4 md:w-1/2 lg:w-1/4">
                                <div className="h-full p-8 text-center transition duration-200 rounded-xl hover:bg-white hover:shadow-xl">
                                    <div className="inline-flex items-center justify-center w-16 h-16 mx-auto mb-6 text-white bg-blue-500 rounded-xl">

                                        <BiAward className="w-8 h-8" />

                                    </div>
                                    <h3 className="mb-4 text-xl font-bold leading-tight md:text-2xl"> خدمة التقييم</h3>
                                    <p className="font-medium ">تقييم جميع انواع العقارات معتمد لدى الهئية السعودية للمقيمين المعتمدين</p>
                                </div>
                            </div>
                            <div className="w-full px-4 md:w-1/2 lg:w-1/4">
                                <div className="h-full p-8 text-center transition duration-200 rounded-xl hover:bg-white hover:shadow-xl">
                                    <div className="inline-flex items-center justify-center w-16 h-16 mx-auto mb-6 text-white bg-blue-500 rounded-xl">
                                        <BiNews className="w-8 h-8" />
                                    </div>
                                    <h3 className="mb-4 text-xl font-bold leading-tight md:text-2xl"> خدمة المزادات</h3>
                                    <p className="font-medium ">تقديم خدمة المزادات العقارية على جميع انواع العقارات بمنهجية عالية</p>
                                </div>
                            </div>
                            <div className="w-full px-4 md:w-1/2 lg:w-1/4">
                                <div className="h-full p-8 text-center transition duration-200 rounded-xl hover:bg-white hover:shadow-xl">
                                    <div className="inline-flex items-center justify-center w-16 h-16 mx-auto mb-6 text-white bg-blue-500 rounded-xl">
                                        <BiDirections className="w-8 h-8" />
                                    </div>
                                    <h3 className="mb-4 text-xl font-bold leading-tight md:text-2xl"> خدمة الاستشارات&nbsp;</h3>
                                    <p className="font-medium ">تقديم خدمة الاستشارات العقارية ودراسة أفضل استخدام ودراسة الجدوى العقارية</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="py-5 overflow-hidden bg-white md:pt-5" style={{ backgroundImage: `url(${"img/patternWhite.svg"})`, backgroundPosition: "center" }}>
                    <h1 className="mb-6 text-2xl font-bold text-center" > المشاريع</h1>
                    <div className="grid grid-cols-2 frame  gap-5 place-items-center md:grid-cols-5  h-[350px] md:h-auto overflow-x-scroll shadow-sky-400 w-[98%]">
                        {
                            data.Gallery.map(rep => {

                                return (
                                    <div className="flex flex-col h-full w-full my-1 rounded-lg items-center gap-2">
                                        <img className="w-32 h-32" src={rep.Image} />
                                        {/* <div className="text-sm">{rep.title}</div> */}
                                    </div>
                                )
                            })
                        }
                    </div>


                </section>

                <section className="p-2 overflow-hidden bg-white md:pt-5" style={{ backgroundImage: `url(${"img/patternWhite.svg"})`, backgroundPosition: "center" }}>
                    <h1 className="my-3 text-2xl font-bold text-center" > تواصل معنا</h1>
                    <div className="flex flex-col gap-5">
                        <div className="flex flex-row items-center gap-5"> <BiMapPin className="w-8 h-8" />العنون :{data.Contact.address} </div>
                        <div className="flex flex-row items-center gap-5"> <BiMobileAlt className="w-8 h-8" />جوال :{data.Contact.phone} </div>
                        <div className="flex flex-row items-center gap-5"> <BiPhone className="w-8 h-8" />هاتف :{data.Contact.telphone} </div>
                    </div>

                    <a target="_blank" href="https://maps.app.goo.gl/6kjsW1q9sWnLzsM29" className="p-1 text-white flex my-10 flex-row mx-auto justify-center items-center bg-blue-500 rounded-lg gap-3 w-56 h-10">

                        <BiCurrentLocation className="w-8 h-8" />
                        الوصول الى موقع المكتب
                    </a>

                    <SocialMedia />


                </section>

            </div >
        </>




    )
}

